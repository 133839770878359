<template>
  <el-card>
    <div slot="header">
      <h3>礼品商城</h3>
    </div>
    <el-row :gutter="10">
      <el-col :span="4" v-for="item in gifts" :key="item.id" class="text-center margin-bottom">
        <el-image :src="item.url" fit="fill"></el-image>
        <el-row>
          <el-col :span="12"><b>{{ item.name }}</b></el-col>
          <el-col :span="12"><b class="text-red">￥ {{item.price }}</b></el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-card>


</template>

<script>
  import axios from "axios";
  import {getMallPage} from "../../../api/GiftApi";

  export default {
    name: 'MallIndex',
    data() {
      return {
        url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',

        gifts: []
      }
    },
	create(){
		
	},

    mounted() {	 
      // getMallPage().then(res => {
      //   //this.gifts = res.rows;
      // }) 
	  const params = new URLSearchParams();
	  params.append('uid', this.id);  
	  
	  axios.post(this.apiUrl+'/api/user/getGift',params)
	  .then((response) => {  
	  	console.log(response.data.data);
	  	this.gifts = response.data.data; 
	  			  
	  }) 
	  .catch(function(error) {
	    console.log(error);
	  });
    },
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    h3 {
      margin: 0px;
      padding: 0px;
    }

    .el-image {
      width: 120px;
      height: 120px;
    }

    .margin-bottom {
      margin-bottom: 20px;
    }

    .text-red {
      color: indianred;
    }
  }


</style>
